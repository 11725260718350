import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'
import {
    isObject,
    get,
    identity,
    kebabCase,
    isString,
    isEmpty,
    isDate,
    includes,
    has,
    merge,
} from 'lodash-es'
import { withI18n, getLanguage, translate as t } from '@src/i18n/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin as linkedInIcon } from '@fortawesome/free-brands-svg-icons'
import { faGlobe as websiteIcon } from '@fortawesome/free-solid-svg-icons'

import {
    faPhoneSquareAlt as telephoneIcon,
    faEnvelope as emailIcon,
} from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

import profileImageSrc from '@static/me.jpeg'

const theme = {
    aside: {
        color: '#E6E8EF',
        borderColor: '#9C9897',
    },

    section: {
        title: {
            color: '#E6E8EF',
        },
    },
    header: {
        color: '#434343',
        textColor: '#E6E8EF',
        borderColor: '#E6E8EF',
    },
}

const TagList = styled.div`
    margin-top: 1rem;
    margin-right: -0.5rem;
    display: flex;
    flex-wrap: wrap;
`

const Tag = styled.span`
    display: inline-block;
    padding: 0.35rem 0.6rem;
    border-radius: 0.4rem;

    font-size: 1.1rem;
    font-weight: 600;

    background-color: grey;
    color: white;

    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &:last-child {
        margin-right: 0;
    }
`

const Icon = styled(FontAwesomeIcon)`
    width: ${({ size = 2 }) => `${size}rem !important`};
    height: ${({ size = 2 }) => `${size}rem !important`};

    path {
        ${({ fill = theme.header.color }) => `fill: ${fill};`}
    }
`

const Root = styled.div`
    @media screen {
        margin: 0 auto;
        width: 21cm;
        min-height: 29cm;
        background: white;
    }

    height: 100%;
    padding: 1.3cm 1cm;

    position: relative;

    &,
    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    }

    display: flex;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: ${5.2 + 0.5}cm;
        background-color: ${theme.header.color};
        z-index: 0;
    }

    & > * {
        position: relative;
        z-index: 1;
    }
`

const Text = styled(({ children, ...props }) => {
    return (
        <div
            {...props}
            {...(isObject(children)
                ? {
                      dangerouslySetInnerHTML: {
                          __html: get(children, 'childMarkdownRemark.html', 'Invalid Markdown'),
                      },
                  }
                : { children })}
        ></div>
    )
})`
    font-size: 1.4rem;

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }
`

const cssProp = (prop, cssAttribute = kebabCase(prop), mapping = {}) => (props) =>
    props[prop] && `${cssAttribute}: ${get(mapping, props[prop], props[prop])};`

const Section = styled.section`
    display: flex;
    flex-direction: column;
    ${cssProp('height')}
    ${cssProp('backgroundColor')}
    ${cssProp('color')}
    ${cssProp('textAlign')}

    ${cssProp('width')}


    ${cssProp('align', 'margin-left', { left: '0', center: 'auto', right: 'auto' })}
    ${cssProp('align', 'margin-right', { left: 'auto', center: 'auto', right: '0' })}


    ${cssProp('valign', 'justify-content', { left: 'flex-start', right: 'flex-end' })}
`

const SectionTitle = styled.h2`
    text-transform: uppercase;
    font-size: 1.6rem;
    padding: 0.3cm 0 0.2cm 0;
    letter-spacing: 0.05cm;
    font-weight: 700;

    ${({ variant }) =>
        variant == 'aside'
            ? css`
                  margin: 0.2cm 0 0.2cm 0;
                  border-bottom: 1px solid ${theme.aside.borderColor};
              `
            : css`
                  margin: 0.6cm 0 0.6cm 0;
                  background-color: ${theme.section.title.color};
                  text-align: center;
              `}
`

const SectionItemTitle = styled.h3`
    font-size: 1.6rem;
    margin: 0;
    margin-bottom: 0.2rem;

    display: inline-flex;
    align-items: center;
`

const SectionItemSmallText = styled.p`
    font-size: 1.4em;
    opacity: 0.6;
    margin-top: 0em;
    margin-bottom: 0.4em;
    strong {
        letter-spacing: 1px;
    }
`

const dateToString = (date) => {
    if (date) {
        return date.format ? date.format('MMM YYYY') : date
    } else {
        return ''
    }
}

const SectionItem = styled(
    ({
        title,
        subtitle,
        icon,
        variant,
        location,
        link = {},
        description,
        tags = [],
        startDate,
        endDate,
        ...props
    }) => {
        const Wrapper = !isEmpty(link) ? 'a' : 'div'
        return (
            <Wrapper {...props} {...link}>
                <SectionItemTitle>
                    {icon && (
                        <Icon
                            {...{
                                ...(isObject(icon) && has(icon, 'size') ? icon : { icon }),

                                style: {
                                    marginRight: '0.8rem',
                                    ...(isObject(icon) && has(icon, 'icon') ? icon.style : {}),
                                },
                            }}
                        />
                    )}
                    {t(title)}
                </SectionItemTitle>
                {subtitle && <SectionItemSmallText>{subtitle}</SectionItemSmallText>}
                {(startDate || endDate) && (
                    <SectionItemSmallText
                        style={{
                            position: variant == 'aside' ? 'static' : 'absolute',
                            top: 0,
                            right: 0,
                        }}
                    >
                        {dateToString(startDate)}
                        {startDate && endDate && ' - '}
                        {dateToString(endDate)}
                    </SectionItemSmallText>
                )}
                {location && <SectionItemSmallText>{location}</SectionItemSmallText>}
                <Text style={{ textAlign: 'justify' }}>{description}</Text>
                {tags.length > 0 && (
                    <TagList>
                        {tags.map((tag) => (
                            <Tag>{tag}</Tag>
                        ))}
                    </TagList>
                )}
            </Wrapper>
        )
    }
)`
    margin-bottom: ${({ margin = 'normal' }) => (margin == 'small' ? '0.25cm' : '0.45cm')};

    &:last-child {
        margin-bottom: 0;
    }

    position: relative;
`

const dateSorter = (a, b) =>
    isEmpty(a.endDate) && isEmpty(b.endDate) && !isEmpty(a.startDate) && !isEmpty(b.startDate)
        ? moment(b.startDate).unix() - moment(a.startDate).unix()
        : isEmpty(a.endDate)
        ? -1
        : isEmpty(b.endDate)
        ? 1
        : moment(b.endDate).unix() - moment(a.endDate).unix()

const EntrySection = ({
    title,

    variant,
    entries,
    mapper = identity,
    sorter = (a, b) => 1,
}) => (
    <Section>
        <SectionTitle {...{ variant }}>{t(title)}</SectionTitle>
        {entries
            .filter(Boolean)
            .sort(sorter)
            .map((rawEntry, key) => {
                const entry = mapper(rawEntry)
                return <SectionItem {...{ key, variant }} {...entry} />
            })}
    </Section>
)

const Divider = styled.hr`
    ${cssProp('color')}
    ${cssProp('width')}
`

const Title = styled.h1`
    font-size: 0.8cm;
    font-weight: 400;
    letter-spacing: 0.08cm;
    text-transform: uppercase;

    margin-top: 0cm;
    margin-bottom: 0.3cm;
`

const Subtitle = styled.p`
    margin-top: 0.25cm;
    font-size: 0.35cm;
    letter-spacing: 0.04cm;
    text-transform: uppercase;
`

const ProfileImage = styled.img`
    width: 100%;
    border-radius: 100%;
`

const Aside = styled.aside`
    margin-right: 1cm;
    padding: 0.8cm 0.5cm;

    width: 7.5cm;
    min-width: 7.5cm;
    height: 100%;

    flex-shrink: 1;

    background-color: ${theme.aside.color};
`

const Content = styled.main``

const asDate = (dateOrText) => {
    try {
        return isEmpty(dateOrText) ? t('Present') : moment(dateOrText)
    } catch (e) {
        return dateOrText
    }
}

const CVPage = withI18n(({ data, location, ...props }) => {
    const [language, setLanguage] = useState('en')
    const [showTeaching, setShowTeaching] = useState(false)

    console.log({ data })
    const personalData = data.me.nodes[0]

    const [{ telephone, email }, setExtraData] = useState({})

    const developmentJobPositions = data.jobPositions.nodes.filter(
        (p) => includes(p.tags, t('Development')) && p.type != 'Apprenticeship'
    )
    const teachingJobPositions = data.jobPositions.nodes.filter(
        (p) => includes(p.tags, t('Education')) && p.type != 'Apprenticeship'
    )
    const education = data.education.nodes

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        setShowTeaching(params.has('showTeaching'))

        setExtraData({
            telephone: params.get('telephone'),
            email: params.get('email'),
        })
    }, [])

    return (
        <Root>
            <Helmet>
                <style>{`
                    @media screen {
                        body {
                            background-color: #1d1d1d;
                        }
                    }

                    html {
                        font-size: 10px;
                    }

                    body {
                        font-family: 'PT Sans', sans-serif;
                        letter-spacing: 0.2px;
                    }

                    a {
                        color: black;
                        text-decoration: none;
                    }
                    
                    a:hover {
                        text-decoration: underline;
                    }

                    h1,h2,h3,h4,h5,h6 {
                        
                        letter-spacing: 1px;
                    }
                `}</style>
                <link
                    href="https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&family=PT+Sans:wght@400;700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <Aside>
                <Section width="85%" align="center">
                    <ProfileImage src={profileImageSrc} />
                </Section>

                <Section variant="aside">
                    <SectionTitle variant="aside">About me</SectionTitle>
                    <Text style={{ textAlign: 'justify' }}>{personalData.shortBio}</Text>
                </Section>
                <EntrySection
                    variant="aside"
                    title="Contact"
                    entries={[
                        ...(email && [
                            {
                                title: 'arypbatista@gmail.com',
                                icon: {
                                    icon: emailIcon,
                                    size: 2,
                                    style: { paddingLeft: '0.1rem' },
                                },
                                link: {
                                    href: 'mailto:arypbatista@gmail.com',
                                },
                            },
                        ]),

                        ...(telephone && [
                            {
                                title: '+' + telephone,
                                icon: telephoneIcon,
                                link: {
                                    href: `tel:+${telephone.trim()}`,
                                },
                            },
                        ]),

                        {
                            title: 'arybatista.ar',
                            icon: websiteIcon,
                            link: {
                                href: 'https://arybatista.ar',
                                target: '_blank',
                            },
                        },

                        {
                            title: 'arybatista.ar/linkedin',
                            icon: linkedInIcon,
                            link: {
                                href: 'https://arybatista.ar/linkedin',
                                target: '_blank',
                            },
                        },
                    ]}
                    mapper={(e) => ({ ...e, margin: 'small' })}
                />
                <EntrySection
                    variant="aside"
                    title="Education"
                    entries={education}
                    mapper={(entry) => ({
                        margin: 'small',
                        title: entry.degree,
                        subtitle: <strong>{entry.school}</strong>,
                        description: entry.abstract,
                        startDate: asDate(entry.startDate),
                        endDate: asDate(entry.endDate),
                    })}
                    sorter={dateSorter}
                />

                <EntrySection
                    variant="aside"
                    title="Languages"
                    entries={[
                        {
                            title: 'English',
                            subtitle: 'Bilingual',
                        },
                        {
                            title: 'Spanish',
                            subtitle: 'Native',
                        },
                        {
                            title: 'Japanese',
                            subtitle: t('Basic') + ' JLPT N5',
                        },
                    ]}
                    mapper={(e) => ({ ...e, margin: 'small' })}
                />
            </Aside>
            <Content>
                <Section
                    color={theme.header.textColor}
                    textAlign="center"
                    valign="center"
                    height="4.5cm"
                    style={{ paddingBottom: '0.5cm' }}
                >
                    <Title>Ary Pablo Batista</Title>
                    <Divider color={theme.header.textColor} width="30%" />
                    <Subtitle>{personalData.title}</Subtitle>
                </Section>

                <EntrySection
                    title="Working Experience"
                    entries={developmentJobPositions}
                    mapper={(entry) => ({
                        title: entry.position,
                        subtitle: (
                            <span>
                                <strong>{entry.company}</strong>
                                {entry.company && entry.location && ' - '}
                                {entry.location && entry.location}
                            </span>
                        ),
                        tags: entry.technologies,
                        description: entry.abstract,
                        startDate: asDate(entry.startDate),
                        endDate: asDate(entry.endDate),
                    })}
                    sorter={dateSorter}
                />

                {showTeaching && (
                    <EntrySection
                        title="Teaching Experience"
                        entries={teachingJobPositions}
                        mapper={(entry) => ({
                            title: entry.position,
                            subtitle: <strong>{entry.company}</strong>,
                            description: entry.abstract,
                            startDate: asDate(entry.startDate),
                            endDate: asDate(entry.endDate),
                        })}
                        sorter={dateSorter}
                    />
                )}
            </Content>
        </Root>
    )
})

export default CVPage

export const pageQuery = graphql`
    query CVQuery {
        ...globalFragment
        ...allEducationFragment
        ...allJobPositionFragment
    }
`
